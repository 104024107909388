<template lang="pug">
div(:style='styleObject' :class='{ "image-grid--multi": multiView }' class='image-grid bg-grey-100')
  div(class='image-grid__inner')
    div(v-if='items && items.length > 0' class='image-grid__grid')
      img(v-for='image in items' :key='image' v-image-lazy :data-src='image' :data-cloudinary='image && image.includes("http") ? "false" : "t_sm"' class='image-grid__image')
    avatar(v-else :rounded='false' :username='fallbackName' :size='size' color='#fff' backgroundColor='var(--grey-100)' class='image-grid__fallback')
  slot
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue'

const props = defineProps({
  size: { type: Number, default: 40 },
  aspectRatio: { type: Number, default: 1 },
  images: { type: Array as PropType<string[]>, required: true },
  fallbackName: String,
})
const items = computed(() => (props.images ? props.images.slice(0, multiView.value ? 4 : 1) : []))
const multiView = computed(() => props.images.length > 2 && props.size > 80)
const styleObject = computed(() => ({ width: props.size + 'px', paddingTop: 100 * props.aspectRatio + '%' }))
</script>
<style lang="stylus">
@import '../styles/variables.styl'

:root
  --bg-image-grid: linear-gradient(135deg, rgba(48,73,98,0.05) 5%, rgba(48,73,98,0.075) 100%)
  --shadow-image-grid: inset 0 0 20px rgba(96, 125, 139, 0.05)
.image-grid
  overflow: hidden
  position: relative
  width: 100%
  box-shadow: var(--shadow-image-grid)
  &__inner
    width: 100%
    position: absolute
    height: 100%
    top: 0
    left: 0
  &__grid
    padding: 5px
    height: 100%
    position: relative
    display: flex
    flex-wrap: wrap
    ~/__grid &
      height: 50%
      padding: 10px
    ~/--multi &
      > *
        width: 50%
  &__image
    display: flex
    width: 100%
    mix-blend-mode: multiply
    aspect-ratio: 1.35
    object-fit: contain
  &__fallback
    position: absolute
    width: 100%!important
    height: 100%!important
    top: 0
    left: 0
</style>
