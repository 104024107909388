<template lang="pug">
image-grid(:size='size' :aspectRatio='aspectRatio' :images='images' :fallbackName='fallbackName' class='rounded-lg')
  slot
</template>

<script setup lang="ts">
import ImageGrid from '@/components/ImageGrid.vue'
import type { IInquiry } from '@/pages/inquiries/types'
import type { IProjectData } from '@/pages/projects/types'
import { computed, type PropType } from 'vue'

const props = defineProps({
  size: { type: Number, default: 40 },
  aspectRatio: { type: Number, default: 1 },
  project: { type: Object as PropType<IProjectData | IInquiry>, required: true },
})

const internalItems = computed(
  () =>
    props.project?.items?.filter(
      (item) => item.image && !item.image.includes('http') && item.image !== '/Products/Assets/',
    ) || [],
)
const images = computed(() => internalItems.value.map((item) => item.image))
const fallbackName = computed(() => (props.project as IProjectData).title || (props.project as IInquiry).id)
</script>
